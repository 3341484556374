import { 
        BATCH_DELETE_FAIL,
        BATCH_DELETE_REQUEST,
        BATCH_DELETE_SUCCESS,
        BATCH_DETAILS_FAIL,
        BATCH_DETAILS_REQUEST,
        BATCH_DETAILS_SUCCESS,
        BATCH_LIST_FAIL, 
        BATCH_LIST_REQUEST, 
        BATCH_LIST_SUCCESS, 
        BATCH_REGISTER_FAIL, 
        BATCH_REGISTER_REQUEST,
        BATCH_REGISTER_SUCCESS,
        BATCH_UPDATE_FAIL,
        BATCH_UPDATE_REQUEST,
        BATCH_UPDATE_RESET,
        BATCH_UPDATE_SUCCESS
    } from "../constants/batchConstants"


export const batchListReducer = (state = {batch: []}, action) => {
    switch (action.type) {
        case BATCH_LIST_REQUEST:
            return {
                loading: true,
                batch: []
            }
        case BATCH_LIST_SUCCESS:
            return { 
                loading: false,
                batch: action.payload
            }
        case BATCH_LIST_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const batchRegisterReducer = (state = {}, action) =>{
    switch (action.type){
        case BATCH_REGISTER_REQUEST:
            return { loading: true }
        case BATCH_REGISTER_SUCCESS:
            return { loading: false, batch: action.payload}
        case BATCH_REGISTER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const batchDeleteReducer = ( state= {}, action) => {
    switch(action.type){
        case BATCH_DELETE_REQUEST:
            return { loading: true }
        case BATCH_DELETE_SUCCESS:
            return { loading: false, success:true}
        case BATCH_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const batchDetailsReducer = (state={ },action) => {
    switch(action.type){
        case BATCH_DETAILS_REQUEST:
            return {...state}
        case BATCH_DETAILS_SUCCESS:
            return {initdata: true, batchD: action.payload}
        case BATCH_DETAILS_FAIL:
            return {error: action.payload }
        default:
            return state
    }
}

export const batchUpdateReducer = (state = {batch: {}}, action) => {
    switch(action.type){
        case BATCH_UPDATE_REQUEST:
            return {loading: true}
        case BATCH_UPDATE_SUCCESS:
            return {initdata: true, batch: action.payload}
        case BATCH_UPDATE_FAIL:
            return {loading: false,error: action.payload }
        case BATCH_UPDATE_RESET:
            return {batch: {}}
        default:
            return state
    }
}