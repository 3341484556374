import axios from "axios"
import { 
    USER_DETAILS_FAIL,
    USER_DETAILS_REQUEST,
        USER_DETAILS_RESET,
        USER_DETAILS_SUCCESS,
        USER_LOGIN_FAIL, 
        USER_LOGIN_REQUEST, 
        USER_LOGIN_SUCCESS, 
        USER_LOGOUT
        } from "../constants/userConstants"


export const Login = (username,password) => async (dispatch) =>{
    try {
        dispatch({ type: USER_LOGIN_REQUEST })

        /*const config = {
            headers:{
                'Content-Type': 'application/json'
            }
       }*/

        const {data} = await axios.post('/api/users/login',{username,password})

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))
        
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message : error.response.message,
        })
    }
}

export const getUserDetails = (id) => async (dispatch) =>{
    try {
        dispatch({
            type: USER_DETAILS_REQUEST
        })

        //const { userLogin: { userInfo } } = getState()

        /*const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
        }*/

        const {data} = await axios.get(`/api/users/${id}`) //config

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: USER_DETAILS_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message : error.message,
        })
    }
}

export const Logout = () => (dispatch) =>{
    localStorage.removeItem('userInfo')
    dispatch({
        type: USER_LOGOUT
    })

    dispatch({
        type: USER_DETAILS_RESET
    })
}