import React, { useEffect, useState } from 'react'
import { Form,FormControl, FormGroup, FormLabel,Button, Row, Col,Alert } from 'react-bootstrap'
import FormContainer from '../components/FormContainer'
import {useDispatch,useSelector} from 'react-redux'
import {Login} from '../actions/userActions'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {useNavigate} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginScreen = () => {

    const dispatch = useDispatch()
    const history = useNavigate()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    
    const userLogin = useSelector(state => state.userLogin)
    const {error, userInfo} = userLogin

    useEffect(()=>{
        if(userInfo){
            history('/admin')
        }
    },[history,userInfo])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(Login(username,password))

        if(error || !error){
            toast.error(error)
        }
    }

   
  return (
    <> 
        <FormContainer>
        <Alert  variant='info'>
            <h5 className='text-center'>User Authentication</h5>
        </Alert>
        
        
        <Row>
            <Col>
            <img
                src='https://mdbootstrap.com/img/new/standard/city/045.webp'
                className='img-thumbnail'
                alt='...'
                />
            </Col>
            <Col>
                <Form onSubmit={submitHandler}>
                    <FormGroup>
                        <FormLabel>User Name</FormLabel>
                        <FormControl 
                            type='text' 
                            placeholder='Enter Username' 
                            value={username} onChange={(e) => setUsername(e.target.value)} >
                        </FormControl>
                    </FormGroup>
                    <FormGroup className='mt-2'>
                        <FormLabel>Password</FormLabel>
                        <FormControl 
                            type='password' 
                            placeholder='Enter password' 
                            value={password} onChange={(e) => setPassword(e.target.value)} >
                        </FormControl>
                    </FormGroup>
                    <div className='d-grid gap-2'>
                        <Button type='submit' variant='danger' size='lg' className='mt-2'>Login</Button>
                    </div>
                </Form>
            </Col>
            </Row>
        </FormContainer>
            <ToastContainer 
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
        />
        
        </>
   
    
  )
}

export default LoginScreen