import React from 'react'
import {Navbar, Nav, Container, NavDropdown} from 'react-bootstrap'
import { useSelector,useDispatch } from 'react-redux'
import { Logout } from '../actions/userActions'
import {LinkContainer} from 'react-router-bootstrap'


const Header = () => {

  const userLogin = useSelector(state => state.userLogin)
  const {userInfo} = userLogin

  const dispatch = useDispatch()

  const logoutHandler = () =>{
    dispatch(Logout())
  }

  return (
    <header>
        <Navbar bg="light" expand="lg" collapseOnSelect>
        <Container>

              <LinkContainer to='/'>
                <Navbar.Brand>DILG-CAREER-IX</Navbar.Brand>
              </LinkContainer>
            
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">

                    {userInfo != null ? (
                      <>

                        <LinkContainer to='/admin/jobposted'>
                          <Nav.Link >
                            <i className='fas fa-server' style={{ fontSize: "1rem" }}></i> <span style={{ fontSize: "1rem" }}>JOB POSTED</span> 
                          </Nav.Link>
                        </LinkContainer>

                        <LinkContainer to='/admin/batch'>
                          <Nav.Link >
                            <i className='fas fa-briefcase' style={{ fontSize: "1rem" }}></i> <span style={{ fontSize: "1rem" }}>MANAGE BATCH</span> 
                          </Nav.Link>
                        </LinkContainer>

                        <LinkContainer to='/admin/jobs'>
                          <Nav.Link >
                            <i className='fas fa-cogs' style={{ fontSize: "1rem" }}> </i> <span style={{ fontSize: "1rem" }}>MANAGE POSITION</span> 
                          </Nav.Link>
                        </LinkContainer>

                        <LinkContainer to='/admin/category'>
                          <Nav.Link>
                            <i className='fas fa-bars' style={{ fontSize: "1rem" }}> </i> <span style={{ fontSize: "1rem" }}>MANAGE CATEGORY</span> 
                          </Nav.Link>
                        </LinkContainer>
      
                      
                        <NavDropdown title={<span style={{ fontSize: "1rem" }}>{userInfo.uname}</span>} id="loginn">
                              <LinkContainer to='/login'>
                                <NavDropdown.Item>
                                  <i className='fas fa-edit' style={{ fontSize: "1rem" }}></i> <span style={{ fontSize: "1rem" }}>Profile</span>
                                </NavDropdown.Item>
                              </LinkContainer>

                            <NavDropdown.Divider />
                
                            <LinkContainer to='/login'>
                                <NavDropdown.Item onClick={logoutHandler}>
                                    <i className='fa fa-sign-out' style={{ fontSize: "1rem" }}></i> <span style={{ fontSize: "1rem" }}>Logout</span>
                                </NavDropdown.Item>
                            </LinkContainer>
                
                          </NavDropdown>
                        </>
                    ):(
                      <>
                        <LinkContainer to='/'>
                          <Nav.Link>
                          <i className='fas fa-search' style={{ fontSize: "1.1rem" }}></i> <span style={{ fontSize: "1.1rem" }}> Find Job</span> 
                          </Nav.Link>
                        </LinkContainer>

                        <LinkContainer to='/'>
                          <Nav.Link >
                            <i className='fas fa-book' style={{ fontSize: "1.1rem" }}></i> <span style={{ fontSize: "1.1rem" }}> About</span> 
                          </Nav.Link>
                        </LinkContainer>

                        <NavDropdown title={<span style={{ fontSize: "1.1rem" }}>LOGIN</span>} id="basic-nav-dropdown">

                        <LinkContainer to='/login'>
                          <NavDropdown.Item>
                              <i className='fas fa-user' style={{ fontSize: "1.1rem" }}></i> <span style={{ fontSize: "1.1rem" }}> Amin</span>
                          </NavDropdown.Item>
                        </LinkContainer>

                        <NavDropdown.Divider />
        
                        <LinkContainer to='/login'>
                          <NavDropdown.Item>
                              <i className='fas fa-user' style={{ fontSize: "1.1rem" }}></i> <span style={{ fontSize: "1.1rem" }}> Board Member</span>
                          </NavDropdown.Item>
                        </LinkContainer>
                        </NavDropdown>
                      </>
                    )}
                    
           
            </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
    </header>
  )
}

export default Header