import { legacy_createStore as createStore, combineReducers, applyMiddleware  } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { jobDeleteReducer, jobDetailsReducer, jobListReducer,jobRegisterReducer, jobUpdateReducer } from './reducers/jobReducers'
import { userDetailsReducer, userLoginReducer } from './reducers/userReducers'
import { batchDeleteReducer, batchDetailsReducer, batchListReducer, batchRegisterReducer, batchUpdateReducer } from './reducers/batchReducers'
import { categoryDeleteReducer, categoryDetailsReducer, categoryListReducer, categoryUpdateReducer } from './reducers/categoryReducers'
import { postListReducer, postRegisterReducer } from './reducers/postReducers'


const reducer = combineReducers({
    jobList: jobListReducer,
    jobRegister: jobRegisterReducer,
    jobDetails: jobDetailsReducer,
    jobEdit: jobUpdateReducer,
    jobDelete: jobDeleteReducer,
    userLogin: userLoginReducer,
    userDetails: userDetailsReducer,
    batchList: batchListReducer,
    batchRegister: batchRegisterReducer,
    batchDelete: batchDeleteReducer,
    batchDetails: batchDetailsReducer,
    batchEdit: batchUpdateReducer,
    categoryList: categoryListReducer,
    categoryEdit: categoryUpdateReducer,
    categoryDetails: categoryDetailsReducer,
    categoryDelete: categoryDeleteReducer,
    postRegister: postRegisterReducer,
    postList: postListReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {
    userLogin: { 
        userInfo: userInfoFromStorage,
    }
}
const middleware = [thunk]

const store = createStore(
    reducer, 
    initialState, 
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store