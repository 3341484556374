import { 
    JOB_DELETE_FAIL,
    JOB_DELETE_REQUEST,
    JOB_DELETE_SUCCESS,
    JOB_DETAILS_FAIL, 
    JOB_DETAILS_REQUEST, 
    JOB_DETAILS_SUCCESS, 
    JOB_LIST_FAIL,
    JOB_LIST_REQUEST,
    JOB_LIST_SUCCESS, 
    JOB_REGISTER_FAIL, 
    JOB_REGISTER_REQUEST, 
    JOB_REGISTER_SUCCESS, 
    JOB_UPDATE_FAIL, 
    JOB_UPDATE_REQUEST,
    JOB_UPDATE_RESET,
    JOB_UPDATE_SUCCESS
} from "../constants/jobConstants"

export const jobListReducer = (state = {jobs: []}, action) => {
    switch (action.type) {
        case JOB_LIST_REQUEST:
            return {
                loading: true,
                jobs: []
            }
        case JOB_LIST_SUCCESS:
            return { 
                loading: false,
                jobs: action.payload
            }
        case JOB_LIST_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const jobIdGetReducer = (state ={jobs:[]},action) =>{

}

export const jobRegisterReducer = (state = {}, action) =>{
    switch (action.type){
        case JOB_REGISTER_REQUEST:
            return { loading: true }
        case JOB_REGISTER_SUCCESS:
            return { loading: false, jobs: action.payload}
        case JOB_REGISTER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const jobDetailsReducer = (state= {},action) => {
    switch(action.type){
        case JOB_DETAILS_REQUEST:
            return {...state}
        case JOB_DETAILS_SUCCESS:
            return {initdata: true, jobD: action.payload}
        case JOB_DETAILS_FAIL:
            return {error: action.payload }
        default:
            return state
    }
}

export const jobUpdateReducer = (state = {job: {}}, action) => {
    switch(action.type){
        case JOB_UPDATE_REQUEST:
            return {loading: true}
        case JOB_UPDATE_SUCCESS:
            return {initdata: true, job: action.payload}
        case JOB_UPDATE_FAIL:
            return {loading: false,error: action.payload }
        case JOB_UPDATE_RESET:
            return {job: {}}
        default:
            return state
    }
}

export const jobDeleteReducer = ( state= {}, action) => {
    switch(action.type){
        case JOB_DELETE_REQUEST:
            return { loading: true }
        case JOB_DELETE_SUCCESS:
            return { loading: false, success:true}
        case JOB_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
