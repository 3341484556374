import { 
    CATEGORY_DELETE_FAIL,
    CATEGORY_DELETE_REQUEST,
    CATEGORY_DELETE_SUCCESS,
    CATEGORY_DETAILS_FAIL,
    CATEGORY_DETAILS_REQUEST,
    CATEGORY_DETAILS_SUCCESS,
    CATEGORY_LIST_FAIL, 
    CATEGORY_LIST_REQUEST, 
    CATEGORY_LIST_SUCCESS, 
    CATEGORY_REGISTER_FAIL, 
    CATEGORY_REGISTER_REQUEST,
    CATEGORY_REGISTER_SUCCESS,
    CATEGORY_UPDATE_FAIL,
    CATEGORY_UPDATE_REQUEST,
    CATEGORY_UPDATE_SUCCESS
} from "../constants/categoryConstants"
import axios from 'axios'

export const listCategory = () => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_LIST_REQUEST })

        const { data } = await axios.get(`/api/category`)

        dispatch({
            type: CATEGORY_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CATEGORY_LIST_FAIL, 
            payload: error.response && error.response.data.message ? 
            error.response.data.message : error.message,
        })
    }
}

export const registerCategory = (catname) => async (dispatch) => {
    //get req.params.batchname,uid
    try {
        dispatch({
        type: CATEGORY_REGISTER_REQUEST
        })

     const {data} = await axios.post('/api/category', {catname})

        dispatch({
        type: CATEGORY_REGISTER_SUCCESS,
        payload: data
        })

    } catch (error) {
        dispatch({
        type: CATEGORY_REGISTER_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message : error.message,
        })
    }
}

export const updateCategorybyId = (catdata) => async (dispatch) => {
    try {
       dispatch({
        type: CATEGORY_UPDATE_REQUEST
       })

       const { data } = await axios.put(`/api/category/${catdata.id}`,catdata)

       dispatch({
        type: CATEGORY_UPDATE_SUCCESS,
        payload: data,
       })

    }catch (error){
        dispatch({
            type: CATEGORY_UPDATE_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message : error.message,
        })
    }
}

export const listCategoryDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_DETAILS_REQUEST })

        const {data} = await axios.get(`/api/category/${id}`)

        dispatch({
            type: CATEGORY_DETAILS_SUCCESS,
            payload: data,
        })

       
    }catch (error){
        dispatch({
            type: CATEGORY_DETAILS_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message : error.message,
        })
    }
}

export const deleteCategory = (id) => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_DELETE_REQUEST })

        await axios.delete(`/api/category/${id}`)

        dispatch({ type: CATEGORY_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: CATEGORY_DELETE_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message : error.message,
        })
    }
}