import React, { useEffect, useState } from 'react'
import { FormGroup,FormLabel,FormControl,Row,Col,Button, Alert, FloatingLabel,Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { listCategory } from '../../actions/categoryActions';
import { listJobDetails, updateJobById } from '../../actions/jobActions';
import { JOB_UPDATE_RESET } from '../../constants/jobConstants';


const EditJob = () => {
  const {id} = useParams();
  
  const [batchname, setBatchname] = useState('')
  const [experince, setExperince] = useState('')
  const [education, setEducation] = useState('')
  const [eligibility, setEligibility] = useState('')
  const [training, setTraining] = useState('')
  const [catid, setCatid] = useState(0)


  const dispatch = useDispatch()
  const history = useNavigate()

  const jobDeta = useSelector(state=> state.jobDetails)
  const { initdata, jobD } = jobDeta

  const jobUpdate = useSelector(state=> state.jobEdit)
  const { initdata: jobSuccess } = jobUpdate

  const categoryList = useSelector(state => state.categoryList)
  const {loading, category} = categoryList

  useEffect(()=> {
      if(jobSuccess){
        dispatch({type: JOB_UPDATE_RESET})
        dispatch(listJobDetails(id))
        toast.success('Succcessfully Updated!')
       
        setTimeout(() => {
          history('/admin/jobs')
        }, 2500);

      }else {
       
        if(initdata){
        
          if(jobD.id == id){
            setBatchname(jobD.jobtitle)
            setExperince(jobD.jobexp)
            setEducation(jobD.education)
            setTraining(jobD.Training)
            setEligibility(jobD.eligibility)
            setCatid(jobD.tblCategoryId)
          }else{
            dispatch(listJobDetails(id))
            dispatch(listCategory())
          }
        }else{
            dispatch(listJobDetails(id))
            dispatch(listCategory())
        }
      }
   },[dispatch,jobD,id,jobSuccess])

 
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(updateJobById({
      id:id,
      batchname,
      experince,
      education,
      training,
      eligibility,
      catid,
    }))
  }


  return (
    <>
          <Alert key='dark' variant='info'>
            EDIT JOB DESCRIPTION
          </Alert>
       <Row>
            <Col sm={12} md={12} xs={12}>
         
            <FormGroup className="mb-3">
                <FormLabel>Job Title:</FormLabel>
                <FormControl type="text" name="batchname" id="batchname" value={batchname || ''} placeholder="Enter job title.." onChange={(e)=> setBatchname(e.target.value)}/>
            </FormGroup>

            <FormGroup className="mb-3">
                <FormLabel>Job Experince:</FormLabel>
                <FormControl type="text" name="experince" id="experince" value={experince || ''} placeholder="Enter job experience.." onChange={(e)=> setExperince(e.target.value)}/>
            </FormGroup>

            <FormGroup className="mb-3">
                <FormLabel>Education:</FormLabel>
                <FormControl type="text" value={education || ''} name="education" id="education" placeholder="Enter Education.." onChange={(e)=> setEducation(e.target.value)}/>
            </FormGroup>

            <FormGroup className="mb-3">
                <FormLabel>Training Experince:</FormLabel>
                <FormControl type="text" value={training || ''} name="training" id="training" placeholder="Enter training.." onChange={(e)=> setTraining(e.target.value)}/>
            </FormGroup>

            <FormGroup className="mb-3">
                <FormLabel>Eligibility:</FormLabel>
                <FormControl type="text" value={eligibility || ''} name="eligibility" id="eligibility" placeholder="Enter Eligibility.." onChange={(e)=> setEligibility(e.target.value)}/>
            </FormGroup>

            <FormGroup>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Select Category"
                    className="mb-3"
                >
                   <Form.Select value={catid} onChange={e=>setCatid(e.target.value)} aria-label="Floating label select example" required>
                        <option value=""></option>
                        { 
                            category.map((x)=>
                            <option key={x.id} value={x.id}>{x.catname}</option>
                            ) 
                        }
                    </Form.Select>
                </FloatingLabel>
            </FormGroup>


            <Button variant="primary" type="submit" onClick={submitHandler} className='me-3'>
            <i className='fas fa-save me-2' />  Save
            </Button>
            <Button variant="secondary" type="submit" onClick={()=> history('/admin/jobs')} >
            <i className='fas fa-arrow-alt-circle-left me-2' />Back
            </Button>
            </Col>
        </Row>
        <ToastContainer 
         position="top-center"
         autoClose={2000}
         hideProgressBar={false}
         newestOnTop={false}
         closeOnClick
         pauseOnFocusLoss
         pauseOnHover
        />
    </>
  )
}

export default EditJob