import axios from 'axios'
import { 
    JOB_DELETE_FAIL,
    JOB_DELETE_REQUEST,
    JOB_DELETE_SUCCESS,
    JOB_DETAILS_FAIL,
    JOB_DETAILS_REQUEST,
    JOB_DETAILS_SUCCESS,
    JOB_LIST_FAIL,
    JOB_LIST_REQUEST,
    JOB_LIST_SUCCESS, 
    JOB_REGISTER_FAIL, 
    JOB_REGISTER_REQUEST,
    JOB_REGISTER_SUCCESS,
    JOB_UPDATE_FAIL,
    JOB_UPDATE_REQUEST,
    JOB_UPDATE_SUCCESS
} from "../constants/jobConstants";

export const listJobs = () => async (dispatch) => {
    try {
        dispatch({ type: JOB_LIST_REQUEST })

        const { data } = await axios.get('/api/jobs')

        dispatch({
            type: JOB_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: JOB_LIST_FAIL, 
            payload: error.response && error.response.data.message ? 
            error.response.data.message : error.message,
        })
    }
}

export const jobRegister = (batchname,experince,education,training,catid,eli) => async (dispatch) => {
    //get req.params.batchname,uid
    try {
        dispatch({
        type: JOB_REGISTER_REQUEST
    })

    const {data} = await axios.post('/api/jobs', {batchname,experince,education,training,catid,eli})

    dispatch({
        type: JOB_REGISTER_SUCCESS,
        payload: data
    })

    } catch (error) {
        dispatch({
            type: JOB_REGISTER_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message : error.message,
        })
    }
}

export const listJobDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: JOB_DETAILS_REQUEST })

        const {data} = await axios.get(`/api/jobs/${id}`)

        dispatch({
            type: JOB_DETAILS_SUCCESS,
            payload: data,
        })

       
    }catch (error){
        dispatch({
            type: JOB_DETAILS_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message : error.message,
        })
    }
}

export const updateJobById = (batchdata) => async (dispatch) => {
    try {
       dispatch({
        type: JOB_UPDATE_REQUEST
       })

       const { data } = await axios.put(`/api/jobs/${batchdata.id}`,batchdata)

       dispatch({
        type: JOB_UPDATE_SUCCESS,
        payload: data,
       })

    }catch (error){
        dispatch({
            type: JOB_UPDATE_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message : error.message,
        })
    }
}

export const deleteJob = (id) => async (dispatch) => {
    try {
        dispatch({ type: JOB_DELETE_REQUEST })

        await axios.delete(`/api/jobs/${id}`)

        dispatch({ type: JOB_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: JOB_DELETE_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message : error.message,
        })
    }
}