import React, { useEffect, useState } from "react";
import { registerBatch } from "../../actions/batchActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Alert,
  Row,
  Col,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
  Form,
} from "react-bootstrap";

const AddBatch = () => {
  const [batchname, setBatchname] = useState("");
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();
  const history = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleSaveData = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      var userid = userInfo.id;
      dispatch(registerBatch(batchname, userid));
      toast.success("New batch was successfully created!");
      setTimeout(() => {
        clearFields(e);
      }, 2000);
    }
    setValidated(true);
  };

  function clearFields(event) {
    Array.from(event.target).forEach((e) => (e.value = ""));
  }

  useEffect(() => {
    if (!userInfo) {
      history("/");
    }
  }, [userInfo]);

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSaveData}>
        <Alert key="dark" variant="info">
          ADD NEW BATCH
        </Alert>
        <Row>
          <Col sm={12} md={12} xs={12}>
            <FormGroup className="mb-3" controlId="formBasicPassword">
              <FormLabel>Batch Description:</FormLabel>
              <FormControl
                required
                type="text"
                value={batchname}
                autoComplete="off"
                placeholder="Enter Batch.."
                onChange={(e) => setBatchname(e.target.value)}
              />
              <FormControl.Feedback type="invalid">
                Batch description is required.
              </FormControl.Feedback>
            </FormGroup>

            <Button variant="primary" type="submit" className="me-1">
              Submit
            </Button>
            <Button
              variant="secondary"
              type="button"
              onClick={() => history("/admin/batch")}
            >
              Back
            </Button>
          </Col>
        </Row>
      </Form>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default AddBatch;
