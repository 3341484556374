import { 
    POST_LIST_FAIL,
    POST_LIST_REQUEST,
    POST_LIST_SUCCESS,
    POST_REGISTER_FAIL, 
    POST_REGISTER_REQUEST, 
    POST_REGISTER_SUCCESS 
} from "../constants/postConstants"


export const postListReducer = (state = {posts: []}, action) => {
    switch (action.type) {
        case POST_LIST_REQUEST:
            return {
                loading: true,
                posts: []
            }
        case POST_LIST_SUCCESS:
            return { 
                loading: false,
                posts: action.payload
            }
        case POST_LIST_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const postRegisterReducer = (state = {}, action) =>{
    switch (action.type){
        case POST_REGISTER_REQUEST:
            return { loading: true }
        case POST_REGISTER_SUCCESS:
            return { loading: false, posted: action.payload}
        case POST_REGISTER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

