import React, { useEffect, useState } from 'react'
import { FormGroup,FormLabel,FormControl,Row,Col,Button, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { listBatchDetails, updateBatchbyId } from '../../actions/batchActions';
import { BATCH_UPDATE_RESET } from '../../constants/batchConstants';
import 'react-toastify/dist/ReactToastify.css';


const EditBatch = () => {

  const {id} = useParams();
  
  const [batchname, setBatchname] = useState('')

  const dispatch = useDispatch()
  const history = useNavigate()

  const batchData = useSelector(state=> state.batchDetails)
  const { initdata, batchD } = batchData

  const batchUpdate = useSelector(state=> state.batchEdit)
  const { initdata: jobSuccess } = batchUpdate


  useEffect(()=> {

      if(jobSuccess){
        dispatch(listBatchDetails(id))
        dispatch({type: BATCH_UPDATE_RESET})
        toast.success('Succcessfully Updated!')
        setTimeout(() => {
          history('/admin/batch')
        }, 2500);

      }else {
       
        if(initdata){
          if(batchD.id == id){
            setBatchname(batchD.batchtitle)
          }else{
            dispatch(listBatchDetails(id))
          }
        }else{
            dispatch(listBatchDetails(id))
        }
      }
   },[dispatch,batchD,id,jobSuccess])

 
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(updateBatchbyId({batchname,id}))
  }


  return (
    <>
          <Alert key='dark' variant='info'>
            EDIT BATCH DESCRIPTION
          </Alert>
       <Row>
            <Col sm={12} md={12} xs={12}>
         
            <FormGroup className="mb-3">
                <FormLabel>Job Title:</FormLabel>
                <FormControl type="text" name="batchname" id="batchname" value={batchname || ''} placeholder="Enter batch description.." onChange={(e)=> setBatchname(e.target.value)}/>
            </FormGroup>

            <Button variant="primary" type="submit" onClick={submitHandler} className='me-3'>
            <i className='fas fa-save me-2' />  Save
            </Button>
            <Button variant="secondary" type="submit" onClick={()=> history('/admin/batch')} >
            <i className='fas fa-arrow-alt-circle-left me-2' />Back
            </Button>
            </Col>
        </Row>
        <ToastContainer 
         position="top-center"
         autoClose={2000}
         hideProgressBar={false}
         newestOnTop={false}
         closeOnClick
         pauseOnFocusLoss
         pauseOnHover
        />
    </>
  )
}

export default EditBatch