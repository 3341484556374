export const JOB_LIST_REQUEST = 'JOB_LIST_REQUEST'
export const JOB_LIST_SUCCESS = 'JOB_LIST_SUCCESS'
export const JOB_LIST_FAIL = 'JOB_LIST_FAIL'

export const JOB_REGISTER_REQUEST = 'JOB_REGISTER_REQUEST'
export const JOB_REGISTER_SUCCESS = 'JOB_REGISTER_SUCCESS'
export const JOB_REGISTER_FAIL = 'JOB_REGISTER_FAIL'

export const JOB_DETAILS_REQUEST = 'JOB_DETAILS_REQUEST'
export const JOB_DETAILS_SUCCESS = 'JOB_DETAILS_SUCCESS'
export const JOB_DETAILS_FAIL = 'JOB_DETAILS_FAIL'

export const JOB_UPDATE_REQUEST = 'JOB_UPDATE_REQUEST'
export const JOB_UPDATE_SUCCESS = 'JOB_UPDATE_SUCCESS'
export const JOB_UPDATE_FAIL = 'JOB_UPDATE_FAIL'
export const JOB_UPDATE_RESET = 'JOB_UPDATE_RESET'

export const JOB_DELETE_REQUEST = 'JOB_DELETE_REQUEST'
export const JOB_DELETE_SUCCESS = 'JOB_DELETE_SUCCESS'
export const JOB_DELETE_FAIL = 'JOB_DELETE_FAIL'