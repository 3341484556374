
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {Container} from 'react-bootstrap';
//import HomeScreen from './screens/HomeScreen';
import LoginScreen from './screens/LoginScreen';
import AdminScreen from './screens/AdminScreen';
import JobViewScreen from './screens/JobViewScreen';
import BatchScreen from './screens/BatchScreen';
import AdminJobScreen from './screens/AdminJobScreen';
import AdminCategory from './screens/AdminCategory';
import AddCategory from './screens/Category/AddCategory';
import EditCategory from './screens/Category/EditCategory';
import AddJob from './screens/Job/AddJob';
//import AddPosting from './screens/Posting/AddPosting';
import JobPosted from './screens/Posting/JobPosted';
import AddBatch from './screens/Batch/AddBatch';
import Header from './components/Header';
import Footer from './components/Footer';
import EditJob from './screens/Job/EditJob';
import EditBatch from './screens/Batch/EditBatch';
import BatchViewScreen from './screens/BatchViewScreen';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
      <Header />
      <main className='py-3'>
        <Container>
          <Routes>
            <Route exact path='/' element={<JobPosted />} />
            <Route path='/login' element={<LoginScreen />} />

            <Route path='/batch/create' element={<AddBatch />} />
            
            <Route path='/job/create' element={<AddJob />} />
            <Route path='/job/:id' element={<EditJob />} />
            <Route path='/viewjob/:id' element={<JobViewScreen />} />

            <Route path='/category/create' element={<AddCategory />} />
            <Route path='/category/:id' element={<EditCategory />} />

            <Route path='/admin' element={<AdminScreen />} />
            <Route path='/admin/jobs' element={<AdminJobScreen />} />
            <Route path='/admin/batch' element={<BatchScreen />} />
            <Route path='/admin/viewbatch/:id' element={<BatchViewScreen />} />
            <Route path='/admin/batch/:id' element={<EditBatch />} />
            <Route path='/admin/jobposted' element={<JobPosted />} />
            <Route path='/admin/category' element={<AdminCategory />} />
            
          </Routes>
        </Container>
      </main>
      <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
