import { 
    POST_LIST_FAIL,
    POST_LIST_REQUEST,
    POST_LIST_SUCCESS,
    POST_REGISTER_FAIL, 
    POST_REGISTER_REQUEST, 
    POST_REGISTER_SUCCESS 
} from "../constants/postConstants"
import axios from 'axios'


export const listPosted = () => async (dispatch) => {
    try {
        dispatch({ type: POST_LIST_REQUEST })

        const { data } = await axios.get('/api/post')

        dispatch({
            type: POST_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: POST_LIST_FAIL, 
            payload: error.response && error.response.data.message ? 
            error.response.data.message : error.message,
        })
    }
}

export const postRegister = (jobid,eligible,available,empstatus,salary,assign,uid,closedate,postdate) => async (dispatch, getState) => {
    //get req.params.batchname,uid
    try {
        dispatch({
        type: POST_REGISTER_REQUEST
    })

   // const { userLogin: { userInfo } } = getState()

    const {data} = await axios.post('/api/post', {jobid,eligible,available,empstatus,salary,assign,uid,closedate,postdate})

    dispatch({
        type: POST_REGISTER_SUCCESS,
        payload: data
    })

    } catch (error) {
        dispatch({
            type: POST_REGISTER_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message : error.message,
        })
    }
}