export const BATCH_LIST_REQUEST = 'BATCH_LIST_REQUEST'
export const BATCH_LIST_SUCCESS = 'BATCH_LIST_SUCCESS'
export const BATCH_LIST_FAIL = 'BATCH_LIST_FAIL'

export const BATCH_REGISTER_REQUEST = 'BATCH_REGISTER_REQUEST'
export const BATCH_REGISTER_SUCCESS = 'BATCH_REGISTER_SUCCESS'
export const BATCH_REGISTER_FAIL = 'BATCH_REGISTER_FAIL'

export const BATCH_DELETE_REQUEST = 'BATCH_DELETE_REQUEST'
export const BATCH_DELETE_SUCCESS = 'BATCH_DELETE_SUCCESS'
export const BATCH_DELETE_FAIL = 'BATCH_DELETE_FAIL'

export const BATCH_DETAILS_REQUEST = 'BATCH_DETAILS_REQUEST'
export const BATCH_DETAILS_SUCCESS = 'BATCH_DETAILS_SUCCESS'
export const BATCH_DETAILS_FAIL = 'BATCH_DETAILS_FAIL'

export const BATCH_UPDATE_REQUEST = 'BATCH_UPDATE_REQUEST'
export const BATCH_UPDATE_SUCCESS = 'BATCH_UPDATE_SUCCESS'
export const BATCH_UPDATE_FAIL = 'BATCH_UPDATE_FAIL'
export const BATCH_UPDATE_RESET = 'BATCH_UPDATE_RESET'
