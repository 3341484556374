import React, { useEffect } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-toastify/dist/ReactToastify.css';
import { Row,Col,Button } from 'react-bootstrap';
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate,Link } from 'react-router-dom';
import { deleteCategory, listCategory } from '../actions/categoryActions';
import Moment from 'moment'
import Loader from '../components/Loader';


const AdminCategory = () => {

  const dispatch = useDispatch()
  const history = useNavigate()
  const { SearchBar } = Search;

  const categoryList = useSelector(state => state.categoryList)
  const {loading, category} = categoryList

  const userLogin = useSelector(state => state.userLogin)
  const {userInfo} = userLogin

  useEffect(() => {
    if(!userInfo){
        history('/')
    } else {
        dispatch(listCategory())
    }
  }, [dispatch])


  const columns = [
    { dataField: "id", text: "Catid.",editable: false },
    { dataField: "catname", text: "Category Title"},
    { dataField: "createdAt", text: "Date Added", formatter: dateFormatter, editable: false},
    { dataField: "edit", 
      text: "Edit",
      sort: false,
      formatter: editFormatter,
      headerAttrs: { width: 50 },
      //attrs: { width: 50, className: "EditRow" },
      editable: false 
    },
    { dataField: "delete", 
      text: "Delete",
      sort: false,
      formatter: delFormatter,
      headerAttrs: { width: 50 },
      //attrs: { width: 50, className: "EditRow" },
      editable: false 
    }
  ]

  function delFormatter(cell, row, rowIndex, formExtraData) { 
    return ( 
            < div 
                style={{ textAlign: "center",
                  cursor: "pointer",
                  lineHeight: "normal", color: "red" }}>
              <i className='fas fa-trash' onClick={()=> delFormatHandleDelete(row.id) }></i>
            </div> 
        ); 
  } 
  
  const delFormatHandleDelete = (id) =>{
    if(window.confirm('Are you sure?')){
      dispatch(deleteCategory(id))
      dispatch(listCategory())
    }
  }

  function editFormatter(cell, row, rowIndex, formExtraData) { 
    return ( 
          <div
              style={{ textAlign: "center",
                 cursor: "pointer",
                lineHeight: "normal", color: "green" }}>
               <i className='fas fa-pen' onClick={()=> editForm(row.id)}></i>
          </div> 
); } 

const editForm = (id) =>{
  history(`/category/${id}`)
}

  function dateFormatter(cell) {
    if (!cell) {
          return "";
    }
    return `${Moment(cell).format("MMMM, DD YYYY")? Moment(cell).format("MMMM, DD YYYY"):Moment(cell).format("MMMM, DD YYYY") }`;
  }


  return (
    <>
        <Row>
          <Col>
            <Row>
              <Col sm={9} xs={4} md={4}>
                <Button className="mb-3" variant="info" as={Link} to={'/category/create'}>Add New Category</Button>
              </Col>
            </Row>
            {loading && <Loader />}
          <ToolkitProvider
                keyField="id"
                data={ category }
                columns={ columns }
                search
              >
                {
                  props => (
                    <div>
                      <SearchBar { ...props.searchProps } />
                      <hr />
                      <BootstrapTable
                      bordered={false}
                      striped
                      hover
                      condensed
                      wrapperClasses="table-responsive"
                      pagination={paginationFactory()}
                        {...props.baseProps }
                      />
                    </div>
                  )
                }
              </ToolkitProvider>
          </Col>
        </Row>
    </>
  )
}

export default AdminCategory
