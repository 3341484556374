import { 
    BATCH_DELETE_FAIL,
    BATCH_DELETE_REQUEST,
        BATCH_DELETE_SUCCESS,
        BATCH_DETAILS_FAIL,
        BATCH_DETAILS_REQUEST,
        BATCH_DETAILS_SUCCESS,
        BATCH_LIST_FAIL, 
        BATCH_LIST_REQUEST, 
        BATCH_LIST_SUCCESS, 
        BATCH_REGISTER_FAIL, 
        BATCH_REGISTER_REQUEST,
        BATCH_REGISTER_SUCCESS,
        BATCH_UPDATE_FAIL,
        BATCH_UPDATE_REQUEST,
        BATCH_UPDATE_SUCCESS
    } from "../constants/batchConstants"
import axios from 'axios'

export const listBatch = () => async (dispatch) => {
    try {
        dispatch({ type: BATCH_LIST_REQUEST })

        const { data } = await axios.get('/api/batch')

        dispatch({
            type: BATCH_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: BATCH_LIST_FAIL, 
            payload: error.response && error.response.data.message ? 
            error.response.data.message : error.response,
        })
    }
}

export const registerBatch = (batchname,userid) => async (dispatch) => {
                         //get req.params.batchname,uid
    try {
        dispatch({
            type: BATCH_REGISTER_REQUEST
        })

        const {data} = await axios.post('/api/batch', {batchname,userid})
       
        dispatch({
            type: BATCH_REGISTER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: BATCH_REGISTER_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message : error.message,
        })
    }
}

export const deleteBatch = (id) => async (dispatch) => {
    try {
        dispatch({ type: BATCH_DELETE_REQUEST })

        await axios.delete(`/api/batch/${id}`)

        dispatch({ type: BATCH_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: BATCH_DELETE_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message : error.message,
        })
    }
}

export const listBatchDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: BATCH_DETAILS_REQUEST })

        const {data} = await axios.get(`/api/batch/${id}`)

        dispatch({
            type: BATCH_DETAILS_SUCCESS,
            payload: data,
        })

       
    }catch (error){
        dispatch({
            type: BATCH_DETAILS_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message : error.message,
        })
    }
}

export const updateBatchbyId = (batchdata) => async (dispatch) => {
    try {
       dispatch({
        type: BATCH_UPDATE_REQUEST
       })

       const { data } = await axios.put(`/api/batch/${batchdata.id}`,batchdata)

       dispatch({
        type: BATCH_UPDATE_SUCCESS,
        payload: data,
       })

    }catch (error){
        dispatch({
            type: BATCH_UPDATE_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message : error.message,
        })
    }
}

