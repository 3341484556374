import React, { useEffect } from 'react'
import {Row, Col, Button,Alert} from 'react-bootstrap'
import {listBatch,deleteBatch} from '../actions/batchActions'
import { useDispatch, useSelector } from 'react-redux'
import Moment from 'moment'
import {Link,useNavigate} from 'react-router-dom'
import Loader from '../components/Loader'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const BatchScreen = () => {
    
    //const { keyword } = useParams();
    const { SearchBar } = Search;

    const dispatch = useDispatch()
    const history = useNavigate()

    const batchList = useSelector(state => state.batchList)
    const {batch, loading} = batchList

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    
    useEffect(() => {
        if(!userInfo){
            history('/')
        } else {
            dispatch(listBatch())
        }
    }, [dispatch])


    const columns = [
      { dataField: "id", text: "BatchNo",editable: false },
      { dataField: "batchtitle", text: "Batch Description",editable: false },
      { dataField: "createdAt", text: "Date created", formatter: dateFormatter, editable: false},
      { dataField: "batchstatus", text: "Status", 
        formatter: (cell, row, rowIndex, colIndex)=>{
          if(row.batchstatus === 1){
            return (
                <span style={{color: 'green'}}> OPEN</span>
            );
          }else{
            return (
              <span style={{color: 'red'}}> CLOSED</span>
          );
          }
        }
      },
      { dataField: "jobs", 
      text: "View",
      sort: false,
      formatter: viewjobsFormatter,
      headerAttrs: { width: 50 },
      //attrs: { width: 50, className: "EditRow" },
      editable: false 
      },
      { dataField: "edit", 
        text: "Edit",
        sort: false,
        formatter: editFormatter,
        headerAttrs: { width: 50 },
        //attrs: { width: 50, className: "EditRow" },
        editable: false 
      },
      { dataField: "delete", 
        text: "Delete",
        sort: false,
        formatter: delFormatter,
        headerAttrs: { width: 50 },
        //attrs: { width: 50, className: "EditRow" },
        editable: false 
      }
    ]


    function delFormatter(cell, row, rowIndex, formExtraData) { 
      return ( 
              < div 
                  style={{ textAlign: "center",
                    cursor: "pointer",
                    lineHeight: "normal", color: "red" }}>
                <i className='fas fa-trash' onClick={()=> delFormatHandleDelete(row.id) }></i>
              </div> 
          ); 
    } 

    function editFormatter(cell, row, rowIndex, formExtraData) { 
      return ( 
            <div
                style={{ textAlign: "center",
                   cursor: "pointer",
                  lineHeight: "normal", color: "green" }}>
                 <i className='fas fa-pen' onClick={()=> editForm(row.id)}></i>
            </div> 
  ); } 

  function viewjobsFormatter(cell, row, rowIndex, formExtraData) { 
    return ( 
          <div
              style={{ textAlign: "center",
                 cursor: "pointer",
                lineHeight: "normal", color: "maroon" }}>
               <i className='far fa-address-card' onClick={()=> viewjobRoute(row.id)}></i>
          </div> 
); } 
    
    const delFormatHandleDelete = (id) =>{
      if(window.confirm('Do you want to delete batch? Are you sure?')){
        dispatch(deleteBatch(id))
        dispatch(listBatch())
        toast.error('Successfully deleted!')
      }
    }

    const editForm = (id) =>{
      history(`/admin/batch/${id}`)
    }

    const viewjobRoute = (id) =>{
      history(`/admin/viewbatch/${id}`)
    }

    function dateFormatter(cell) {
      if (!cell) {
            return "";
      }
      return `${Moment(cell).format("MMMM, DD YYYY")? Moment(cell).format("MMMM, DD YYYY"):Moment(cell).format("MMMM, DD YYYY") }`;
  }


  return (
    <>
        <Row>
            <Col>
                <Row>
                  <Alert key='dark' variant='info'>
                    LIST OF BATCH
                  </Alert>
                </Row>
    
                {loading && <Loader />}
               
                <ToolkitProvider
                keyField="id"
                data={ batch }
                columns={ columns }
                search
              >
                {
                  props => (

                    <div>
                     
                      <Row>
                        <Col>
                          <SearchBar { ...props.searchProps }/>
                        </Col>
                        <Col sm={4} xs={4} md={4}>
                         <Button className="mb-2" variant="info" as={Link} to={'/batch/create'}><i className='fas fa-plus-square me-1'/>Create Batch</Button>
                        </Col>
                      </Row>
                      <hr />
                      <BootstrapTable
                      bordered={false}
                      striped
                      hover
                      condensed
                      wrapperClasses="table-responsive"
                      pagination={paginationFactory()}
                        {...props.baseProps }
                      />
                    </div>
                  )
                }
              </ToolkitProvider>
              
            </Col>
        </Row>
        <ToastContainer 
         position="top-center"
         autoClose={2000}
         hideProgressBar={false}
         newestOnTop={false}
         closeOnClick
         pauseOnFocusLoss
         pauseOnHover
        />
    </>
  )
}

export default BatchScreen