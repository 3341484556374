
import React, { useEffect, useState } from 'react'
import { FormGroup,FormLabel,FormControl,Row,Col,Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { listCategoryDetails,updateCategorybyId } from '../../actions/categoryActions';
import { CATEGORY_UPDATE_RESET } from '../../constants/categoryConstants';


const EditCategory = () => {
    const {id} = useParams();
    const [catname, setCatname] = useState('')
  
    const dispatch = useDispatch()
    const history = useNavigate()

    const categoryDe = useSelector(state=> state.categoryDetails)
    const { initdata, categoryD } = categoryDe
  
    const categoryUpdate = useSelector(state=> state.categoryEdit)
    const { initdata: categorySuccess } = categoryUpdate
  
    useEffect(()=> {
        if(categorySuccess){
          dispatch({type: CATEGORY_UPDATE_RESET})
          dispatch(listCategoryDetails(id))
          history('/admin/category')
        }else {
          if(initdata){
            if(categoryD.id == id){
              setCatname(categoryD.catname)
            }else{
              dispatch(listCategoryDetails(id))
            }
          }else{
              dispatch(listCategoryDetails(id))
          }
        }
     },[dispatch,categoryD,id,categorySuccess])
  
   
    const submitHandler = (e) => {
      e.preventDefault()
      dispatch(updateCategorybyId({
        id:id,
        catname,
      }))
    }
  
  
    return (
      <>
        <Link to='/admin/category' className='btn btn-warning my-3'>Go Back</Link>
        <Row>
              <Col sm={12} md={12} xs={12}>
           
              <FormGroup className="mb-3">
                  <FormLabel>Batch Title:</FormLabel>
                  <FormControl type="text" value={catname}  onChange={(e)=> setCatname(e.target.value)}/>
              </FormGroup>
             
              <Button variant="primary" type="submit" onClick={submitHandler} className='me-1'>
                  Submit
              </Button>
              </Col>
          </Row>
          <ToastContainer 
           position="top-center"
           autoClose={2000}
           hideProgressBar={false}
           newestOnTop={false}
           closeOnClick
           pauseOnFocusLoss
           pauseOnHover
          />
      </>
    )
  }
  
  export default EditCategory