import React, { useEffect } from 'react'
import {Row, Col, Button,Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Moment from 'moment'
import {Link,useNavigate} from 'react-router-dom'
import Loader from '../components/Loader'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteJob, listJobs } from '../actions/jobActions'


const AdminJobScreen = () => {
    
    //const { keyword } = useParams();
    const { SearchBar } = Search;

    const dispatch = useDispatch()
    const history = useNavigate()


    const jobList = useSelector(state => state.jobList)
    const {jobs, loading} = jobList

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    
    useEffect(() => {
        if(!userInfo){
            history('/')
        } else {
            dispatch(listJobs()) 
        }
    }, [dispatch])


    const columns = [
      { dataField: "jobtitle", text: "Position Title"},
      { dataField: "jobexp", text: "Experince" },
      { dataField: "education", text: "Education" },
      { dataField: "eligibility", text: "Eligibility" },
      { dataField: "Training", text: "Training" },
     // { dataField: "createdAt", text: "Date Added", formatter: dateFormatter, editable: false},
      { dataField: "tblCategory.catname", text: "Category" },
      { dataField: "edit", 
        text: "Edit",
        sort: false,
        formatter: editFormatter,
        headerAttrs: { width: 50 },
        //attrs: { width: 50, className: "EditRow" },
        editable: false 
      },
      { dataField: "delete", 
        text: "Delete",
        sort: false,
        formatter: delFormatter,
        headerAttrs: { width: 50 },
        //attrs: { width: 50, className: "EditRow" },
        editable: false 
      }
    ]

    
    const cellEdit = cellEditFactory({
      mode: 'click',
      blurToSave: true,
      beforeSaveCell(oldValue, newValue, row, column, done) {
        setTimeout(() => {
          if (confirm('Do you want to accep this change?')) {
            done(); // contine to save the changes
          } else {
            done(false); // reject the changes
          }
        }, 0);
        return { async: true };
      }
    });

    function delFormatter(cell, row, rowIndex, formExtraData) { 
      return ( 
              < div 
                  style={{ textAlign: "center",
                    cursor: "pointer",
                    lineHeight: "normal", color: "red" }}>
                <i className='fas fa-trash' onClick={()=> delFormatHandleDelete(row.id) }></i>
              </div> 
          ); 
    } 

    function editFormatter(cell, row, rowIndex, formExtraData) { 
      return ( 
            <div
                style={{ textAlign: "center",
                   cursor: "pointer",
                  lineHeight: "normal", color: "green" }}>
                 <i className='fas fa-pen' onClick={()=> editForm(row.id)}></i>
            </div> 
  ); } 
    
    const delFormatHandleDelete = (id) =>{
      if(window.confirm('Are you sure?')){
        dispatch(deleteJob(id))
        setTimeout(() => {
          dispatch(listJobs())
        }, 1000);
        toast.error('Successfully deleted!')
      }
    }

    const editForm = (id) =>{
      history(`/job/${id}`)
    }

    function dateFormatter(cell) {
      if (!cell) {
            return "";
      }
      return `${Moment(cell).format("MMMM, DD YYYY")? Moment(cell).format("MMMM, DD YYYY"):Moment(cell).format("MMMM, DD YYYY") }`;
  }


  return (
    <>
        <Row>
            <Col>
                <Row>
                  <Alert key='dark' variant='info'>
                    LIST OF POSITION TITLE
                  </Alert>
                </Row>
    
                {loading && <Loader />}
               
                <ToolkitProvider
                keyField="id"
                data={ jobs }
                columns={ columns }
                search
              >
                {
                  props => (
                    <div>
                       <Row>
                        <Col>
                          <SearchBar { ...props.searchProps }/>
                        </Col>
                        <Col sm={4} xs={4} md={4}>
                        <Button className="mb-2" variant="info" as={Link} to={'/job/create'}> <i className='fas fa-plus-square me-1'/> New Job Description</Button>
                        </Col>
                      </Row>
                      <hr />
                      <BootstrapTable
                      bordered={false}
                      striped
                      hover
                      condensed
                      wrapperClasses="table-responsive"
                      pagination={paginationFactory()}
                        {...props.baseProps }
                      />
                    </div>
                  )
                }
              </ToolkitProvider>
              
            </Col>
        </Row>
        <ToastContainer 
         position="top-center"
         autoClose={2000}
         hideProgressBar={false}
         newestOnTop={false}
         closeOnClick
         pauseOnFocusLoss
         pauseOnHover
        />
    </>
  )
}

export default AdminJobScreen