import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {Row, Col, Button,Alert, Container,CardGroup,Card} from 'react-bootstrap'

const BatchViewScreen = () => {
  return (
    <>
       <Container>
        <Row>
            <Col lg={4} sm={4} md={6} xs={4}>
                    <Card>
                    <Card.Header>Hire Position</Card.Header>
                        <Card.Body>
                            <Card.Title>POSITION DETAILS</Card.Title>
                            <Card.Text>
                            <ToolkitProvider
                                keyField="id"
                                data={ posts }
                                columns={ columns }
                                search
                            >
                                {
                                props => (

                                    <div>
                                    
                                    <Row>
                                        <Col>
                                        <SearchBar { ...props.searchProps }/>
                                        </Col>
                                        <Col sm={4} xs={4} md={4}>
                                        <Button className="mb-2" variant="info" as={Link} to={'/post/create'}><i className='fas fa-plus-square me-1'/> Set New Job</Button>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <BootstrapTable
                                    bordered={false}
                                    striped
                                    hover
                                    condensed
                                    wrapperClasses="table-responsive"
                                    pagination={paginationFactory()}
                                        {...props.baseProps }
                                    />
                                    </div>
                                )
                                }
                            </ToolkitProvider>
                            </Card.Text>
                            <Button variant="primary">Go somewhere</Button>
                        </Card.Body>
                    </Card>
            </Col>
            <Col lg={8} sm={4} md={6} xs={8}>
                <Alert key='dark' variant='info'>
                    POSITION HIRING
                </Alert>
            </Col>
        </Row>
       </Container>
    </>
  )
}

export default BatchViewScreen