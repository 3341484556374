import {Row, Col, Button, Alert, FloatingLabel,FormControl, Form, FormGroup} from 'react-bootstrap'
import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jobRegister } from '../../actions/jobActions'
import { listCategory } from '../../actions/categoryActions';

const AddJob = () => {

   const [batchname, setBatchname] = useState('')
   const [experince, setExperince] = useState('')
   const [education, setEducation] = useState('')
   const [training, setTraining] = useState('')
   const [eligibility, setEligibility] = useState('')
   const [catid, setCatid] = useState(0)
  

    const dispatch = useDispatch()
    const history = useNavigate()

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const categoryList = useSelector(state => state.categoryList)
    const {loading, category} = categoryList


    const saveData = (e) =>{
        e.preventDefault();
        dispatch(jobRegister(batchname,experince,education,training,catid,eligibility))
        clearFields(e)
        toast.success('Successfully added!')
    }

    function clearFields(event) {
        Array.from(event.target).forEach((e) => (e.value = ""));
    }

    useEffect(() => {
        if(!userInfo){
            history('/')
        }else{
            dispatch(listCategory())
        } 
    }, [userInfo])

  return (
    <>
         <Alert key='dark' variant='info'>
          ADD NEW JOB DESCRIPTION
        </Alert>
        <Form onSubmit={saveData}>
        <Row>
            <Col sm={12} md={12} xs={12}>
            
            <FormGroup>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Job Title"
                    className="mb-3"
                >
                    <Form.Control required type="text" value={batchname} placeholder="Enter job title.." onChange={(e)=> setBatchname(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid job title.
                    </Form.Control.Feedback>
                </FloatingLabel>
            </FormGroup>
           
            <FormGroup>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Job Experince"
                    className="mb-3"
                >
                    <FormControl required type="text" value={experince} placeholder="Enter job experience.." onChange={(e)=> setExperince(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid job experience.
                    </Form.Control.Feedback>
                </FloatingLabel>
            </FormGroup>
            
            <FormGroup>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Education"
                    className="mb-3"
                >
                    <FormControl required type="text" value={education} placeholder="Enter Education.." onChange={(e)=> setEducation(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid details.
                    </Form.Control.Feedback>
                </FloatingLabel>
            </FormGroup>
            
            <FormGroup>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Training Experince"
                    className="mb-3"
                >
                    <FormControl required type="text" value={training} placeholder="Enter training.." onChange={(e)=> setTraining(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid details.
                    </Form.Control.Feedback>
                </FloatingLabel>
            </FormGroup>

            <FormGroup>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Eligibility"
                    className="mb-3"
                >
                    <FormControl required type="text" value={eligibility} placeholder='Eligibility' onChange={(e)=> setEligibility(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid details.
                    </Form.Control.Feedback>
                </FloatingLabel>
            </FormGroup>

            <FormGroup>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Select Category"
                    className="mb-3"
                >
                   <Form.Select value={catid} onChange={e=>setCatid(e.target.value)} required>
                        <option value=""></option>
                        { 
                            category.map((x)=>
                            <option key={x.id} value={x.id}>{x.catname}</option>
                            ) 
                        }
                    </Form.Select>
                </FloatingLabel>
            </FormGroup>

        
                <Button variant="primary" type="submit" className='me-3'>
                    <i className='fas fa-save me-2' />save
                </Button>
                <Button variant="secondary" type="button" onClick={()=> history('/admin/jobs')} >
                    <i className='fas fa-arrow-alt-circle-left me-2' />Back
                </Button>
            </Col>
        </Row>
        
        </Form>
        <ToastContainer 
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </>
  )
}

export default AddJob