import {Row, Col, Button, FormGroup, FormLabel,FormControl, Form} from 'react-bootstrap'
import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { registerCategory } from '../../actions/categoryActions'


const AddCategory = () => {

    const dispatch = useDispatch()
    const history = useNavigate()

    const [catname, setCatname] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const [validated, setValidated] = useState(false);

    useEffect(() => {
        if(!userInfo){
            history('/')
        } 
    }, [userInfo])

    const handleSaveData = (e) =>{
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }else{
            e.preventDefault();
            dispatch(registerCategory(catname))
            toast.success('Successfully added!')
            setTimeout(()=>{
                clearFields(e)
            },2000);
        }
        setValidated(true);
       
    }

    function clearFields(event) {
        Array.from(event.target).forEach((e) => (e.value = ""));
    }

  return (
    <>
        <Form noValidate validated={validated} onSubmit={handleSaveData}>
            <Row>
                <Col md="auto" className='mb-2'>
                    <h4>Add New Category</h4>
                </Col>
                <Col sm={12} md={12} xs={12}>
                
                    <FormGroup className="mb-3" controlId="formBasicPassword">
                        <FormLabel>Category Name:</FormLabel>
                        <FormControl required type="text" value={catname} placeholder="Enter Category.." onChange={(e)=> setCatname(e.target.value)}/>
                            <FormControl.Feedback type="invalid">
                                Category name is required.
                            </FormControl.Feedback>
                    </FormGroup>
                   
                    <Button variant="primary" type="submit" className='me-1'>
                        Submit
                    </Button>
                    <Button variant="secondary" type="button" onClick={()=> history('/admin/category')} >
                        Back
                    </Button>
                </Col>
            </Row>
        </Form>
        <ToastContainer 
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </>
  )
}

export default AddCategory