import React, { useEffect } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getUserDetails } from '../actions/userActions'


const AdminScreen = () => {

  const dispatch = useDispatch()

  const userLogin = useSelector(state => state.userLogin)
  const {userInfo} = userLogin

  const userDetails = useSelector((state) => state.userDetails)
  const {initdata, user} = userDetails

  const history = useNavigate()

  useEffect(()=>{
    if(!userInfo){
      history('/')
    } else {
       if(initdata){
         if(!user){
            dispatch(getUserDetails(userInfo.empno))
         }
       }else{
         dispatch(getUserDetails(userInfo.empno))
       }
    }
  }, [history,userInfo,user,dispatch,initdata])

  return (
    <div>{user.uname}</div>
  )
}

export default AdminScreen